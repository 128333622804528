export const pipe = (...fns) => (x) => fns.reduce((v, f) => f(v), x);
export const compose = (...fns) => (x) =>
  fns.reduceRight((v, f) => {
    const result = f(v);
    return result;
  }, x);

/**
 * Combines any given props to one final property.
 * @param {*} getPropFns
 * @param {*} separator
 */
export const combineProps = (getPropFns, separator = ' ') => (item) => {
  const combinedProps = getPropFns.reduce((memo, curr) => {
    memo = [...memo, curr(item)];
    return memo;
  }, []);
  return combinedProps.join(separator);
};

/**
 * Function that recursively tries to find a given prop within an object.
 * @param {*} prop The prop or path to find.
 * @param {*} defaultValue A default value when the prop is null or undefined.
 * @returns A function which accepts an object from which we try to get the given 'prop'.
 * @example <caption>Example 1:</caption>
 * firstName: getProp('name') // tries to grab the 'name' property.
 * addressCity: getProp('user.address.city', 'Unknown') // tries to grab a nested property, set to default 'Unknown' when property doesnot exist
 */
export const getProp = (prop, defaultValue = '') => (item) => {
  if (prop.indexOf('.') > -1) {
    const path = prop.split('.');
    const _prop = path.shift();
    if (item === null || item === undefined) {
      if (path.length) {
        return getProp(prop, defaultValue)({});
      }
    }
    const _data = item[_prop];
    return getProp(path.join('.'), defaultValue)(_data);
  } else {
    if (item === null || item === undefined) {
      return defaultValue;
    }
    if (item[prop] === null || item[prop] === undefined) {
      return defaultValue;
    }
    return item[prop];
  }
};
/**
 * Maps over an object with a given 'mapObject'. The mapObject holds the keys and values we use to return a new mapped object.
 * @param {*} mapObject The object with keys and values.
 * @returns A function which accepts an object we try to map over with the given arg 'mapObject'
 * @example
 * const myMappedObject = mapItem({
  name: getProp('name', 'John Doe'),
  address: getProp('user.address.city, 'London'),
  phoneNumber: getProp('user.address.phone', '-'),
})({
  name: null,
  user: {
      address: {
          phone: '1234567890'
      }
  },
});
This will return a new mapped object:
{
    name: 'John Doe',
    address: 'London',
    phone: 1234567890,
}
 */
export const mapItem = (mapObject) => (item) =>
  Object.entries(mapObject).reduce((memo, [key, valueFn]) => {
    memo[key] = valueFn(item);
    return memo;
  }, {});
