// Imports => React
import React, { useState, useEffect, useMemo, memo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, PERMISSIONS, ROLES, THEMES, TITLES, TYPES } from '@constants';

// Imports => Utilities
import {
  AcIsSet,
  AcIsUndefined,
  AcSortBy,
  AcFormatInternalURI,
  AcFormatDate,
  AcFormatRole,
} from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';
import AcEditUserModal from '@molecules/ac-edit-user-modal/ac-edit-user-modal.web';
import AcEditUserCredentialsModal from '@molecules/ac-edit-user-credentials-modal/ac-edit-user-credentials-modal.web';

// Imports => Components
import AcDetailsCard from '@components/ac-details-card/ac-details-card.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcToggleInput from '@atoms/ac-toggle-input/ac-toggle-input.web';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-user-detail-overview-tab',
};

const AcUserDetailOverviewTab = ({ data, store: { users, ui } }) => {
  const { can, is, canAssignRoles } = usePermissions();

  let raw_fields = {
    roles: (data && data.roles.slice()) || [],
  };

  const [fields, setFields] = useState(raw_fields);

  const { handleInputChange } = useFormActions({
    fields,
    setFields,
  });

  useEffect(() => {
    if (canAssignRoles()) users.get_roles();
  }, []);

  const handleUpdateUser = () => {
    if (users.is_busy) return;

    if (AcIsSet(data) && AcIsSet(data.roles)) {
      if (AcIsSet(fields) && AcIsSet(fields.roles)) {
        users.update(data, fields).catch((error) => {
          users.get_by_id(data.id);
        });
      }
    }
  };

  const isRentalCoordinatorAndCompanyIsNotIHCIQIPRental = (role, company) => {
    const isIHCIQIPRental =
      company &&
      company.name &&
      company.name.toUpperCase() === KEYS.IHC_IQIP_RENTAL;

    return role === ROLES.RENTAL_COORDINATOR && !isIHCIQIPRental;
  };

  const displayNotIHCIQIPRentalNotice = (object) => {
    ui.confirm({
      instance: null,
      title: TITLES.ROLE_RESTRICTED_TO_IHC_IQIP_RENTAL,
      content: `<p>This user <em>cannot</em> have the <strong>Rental Coordinator</strong> user role as they do not belong to the <strong>IQIP Rental</strong> company. Please assign the user to the <strong>IQIP Rental</strong> Company first before assigning them the Rental Coordinator User Role.</p>`,
      cancel: null,
      confirm: {
        label: 'Understood',
        callback: () => {
          return ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
        },
      },
    });
  };

  const displayEditCredentialsModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.EDIT_USER_CREDENTIALS,
      body: <AcEditUserCredentialsModal data={data} submit={users.update} />,
      closeable: true,
      visible: true,
      actions: [],
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const displayEditModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: `${TITLES.EDIT_USER}: ${data.name}`,
      body: <AcEditUserModal data={data} submit={users.update} />,
      closeable: true,
      visible: true,
      actions: [],
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const renderDetails = useMemo(() => {
    if (!data) return null;

    const { name, email, phone, company } = data;

    const company_route =
      (can(PERMISSIONS.COMPANY.READ) &&
        company &&
        AcFormatInternalURI(
          { id: company.id, entity: KEYS.COMPANY },
          'View company'
        )) ||
      null;

    const result = [
      {
        label: 'Name',
        value: name || '-',
      },
      {
        label: 'Email address',
        value: email || '-',
      },
      {
        label: 'Phone number',
        value: phone || '-',
      },
      {
        label: 'Company',
        value: (company && company.name) || '-',
        type: TYPES.LINK,
        to: company_route,
      },
    ];

    const edit = can(PERMISSIONS.USER.UPDATE) && displayEditModal;

    return <AcDetailsCard title={TITLES.DETAILS} items={result} edit={edit} />;
  }, [data]);

  const renderRoles = useMemo(() => {
    if (!AcIsSet(data)) return null;
    if (!users.current_roles) return null;

    const collection = [
      ROLES.ADMIN,
      ROLES.RENTAL_COORDINATOR,
      ROLES.COMPANY_ADMIN,
      ROLES.OPERATOR,
      ROLES.SERVICE_ENGINEER,
      ROLES.CDE,
    ];

    const { company } = data;
    const len = collection.length;
    let n = 0;
    let result = [];

    for (n; n < len; n++) {
      const role = collection[n];

      const disabled = !AcIsSet(
        users.current_roles.find((item) => item.name === role)
      );
      let callback = null;

      if (!disabled) {
        callback = async (event, name, value, type, selected) => {
          if (isRentalCoordinatorAndCompanyIsNotIHCIQIPRental(value, company)) {
            displayNotIHCIQIPRentalNotice();
          } else {
            await handleInputChange(event, name, value, type, selected);
            handleUpdateUser();
          }
        };
      }

      const options = {
        type: TYPES.CHECKBOX,
        name: 'roles',
        id: `ac-roles-toggle-${role}`,
        value: role,
        checked: fields.roles && fields.roles.indexOf(role) > -1,
        callback,
        disabled,
      };

      let label = AcFormatRole(role);

      const object = (
        <AcRow key={`ac-roles-toggle-row-${role}`}>
          <AcColumn xs={12}>
            <AcToggleInput {...options}>
              <span
                dangerouslySetInnerHTML={{
                  __html: label,
                }}
              />
            </AcToggleInput>
          </AcColumn>
        </AcRow>
      );

      result.push(object);
    }

    return result;
  }, [data, fields, fields.roles, users.current_roles]);

  const renderCredentials = useMemo(() => {
    if (!data) return null;

    const { jean_lutz_username, basic_password, advanced_password } = data;

    let result = [
      {
        label: 'Username',
        value: jean_lutz_username || '-',
      },
    ];

    if (!AcIsUndefined(basic_password) && is(ROLES.OPERATOR, data.roles))
      result.push({ label: 'Basic password', value: basic_password || '-' });
    if (
      !AcIsUndefined(advanced_password) &&
      is(ROLES.SERVICE_ENGINEER, data.roles)
    )
      result.push({
        label: 'Advanced password',
        value: advanced_password || '-',
      });

    const edit =
      can([
        PERMISSIONS.JEAN_LUTZ_USERNAME.UPDATE,
        PERMISSIONS.BASIC_PASSWORD.UPDATE,
        PERMISSIONS.ADVANCED_PASSWORD.UPDATE,
      ]) && displayEditCredentialsModal;

    return <AcDetailsCard title={TITLES.DETAILS} items={result} edit={edit} />;
  }, [data]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow>
          <AcColumn xs={12} sm={6}>
            <AcRow>
              <AcColumn>
                <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
                  User details
                </AcHeading>
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>{renderDetails}</AcColumn>
            </AcRow>
          </AcColumn>

          <AcColumn xs={12} sm={6}>
            <AcRow>
              <AcColumn>
                <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
                  User roles
                </AcHeading>
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcCard>{renderRoles}</AcCard>
              </AcColumn>
            </AcRow>
          </AcColumn>
        </AcRow>

        {data &&
          (is(ROLES.OPERATOR, data.roles) ||
            is(ROLES.SERVICE_ENGINEER, data.roles)) && (
            <AcCheckPermissions
              allowed={[
                PERMISSIONS.BASIC_PASSWORD.READ,
                PERMISSIONS.ADVANCED_PASSWORD.READ,
              ]}
            >
              <AcRow>
                <AcColumn xs={12} sm={6}>
                  <AcRow className={'h-margin-bottom-25'}>
                    <AcColumn>
                      <AcHeading tag={'h2'} rank={5}>
                        Control unit credentials
                      </AcHeading>
                      <AcRichContent
                        content={
                          '<p>These credentials give access to control units this user is assigned to.</p>'
                        }
                      />
                    </AcColumn>
                  </AcRow>

                  <AcRow>
                    <AcColumn>{renderCredentials}</AcColumn>
                  </AcRow>
                </AcColumn>
              </AcRow>
            </AcCheckPermissions>
          )}
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcUserDetailOverviewTab));
