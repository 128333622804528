import loadable from '@loadable/component';

const AcProjectDetailOverviewTab = loadable(() =>
	import('./overview/overview.web')
);
const AcProjectDetailConfigurationsTab = loadable(() =>
	import('./configurations/configurations.web')
);
const AcProjectDetailPileListTab = loadable(() =>
	import('./pilelist/pilelist.web')
);
const AcProjectDetailReportsTab = loadable(() =>
	import('./reports/reports.web')
);

export default {
	Overview: AcProjectDetailOverviewTab,
	Configurations: AcProjectDetailConfigurationsTab,
	PileList: AcProjectDetailPileListTab,
	Reports: AcProjectDetailReportsTab,
};
