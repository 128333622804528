// Imports => React
import React, { useState, useEffect, useMemo, memo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, PERMISSIONS, ROLES, THEMES, TITLES, TYPES } from '@constants';

// Imports => Utilities
import {
  AcIsSet,
  AcIsUndefined,
  AcFormatInternalURI,
  AcFormatDate,
  AcFormatRole,
} from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';
import AcEditProfileModal from '@molecules/ac-edit-profile-modal/ac-edit-profile-modal.web';
import AcEditProfileCredentialsModal from '@molecules/ac-edit-profile-credentials-modal/ac-edit-profile-credentials-modal.web';

// Imports => Components
import AcDetailsCard from '@components/ac-details-card/ac-details-card.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-profile-overview-tab',
};

const AcProfileOverviewTab = ({ data, store: { profile, ui } }) => {
  const { can, is } = usePermissions();

  const displayEditCredentialsModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.EDIT_PROFILE_CREDENTIALS,
      body: (
        <AcEditProfileCredentialsModal data={data} submit={profile.update} />
      ),
      closeable: true,
      visible: true,
      actions: [],
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const displayEditModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.EDIT_PROFILE,
      body: <AcEditProfileModal data={data} submit={profile.update} />,
      closeable: true,
      visible: true,
      actions: [],
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const renderDetails = useMemo(() => {
    if (!data) return null;

    const { name, email, phone, company } = data;

    const company_route =
      (can(PERMISSIONS.COMPANY.READ) &&
        company &&
        AcFormatInternalURI(
          { id: company.id, entity: KEYS.COMPANY },
          'View company'
        )) ||
      null;

    const result = [
      {
        label: 'Name',
        value: name || '-',
      },
      {
        label: 'Email address',
        value: email || '-',
      },
      {
        label: 'Phone number',
        value: phone || '-',
      },
      {
        label: 'Company',
        value: (company && company.name) || '-',
        type: TYPES.LINK,
        to: company_route,
      },
    ];

    return (
      <AcDetailsCard
        title={TITLES.DETAILS}
        items={result}
        edit={displayEditModal}
      />
    );
  }, [data]);

  const renderSettings = useMemo(() => {
    if (!data) return null;

    const result = [
      {
        label: 'Language',
        value: 'English (EN)' || '-',
      },
      {
        label: 'Unit of measure',
        value: 'Metric' || '-',
      },
      {
        label: 'Timezone',
        value: 'Coordinated Universal Time (UTC)' || '-',
      },
    ];

    return <AcDetailsCard title={TITLES.DETAILS} items={result} />;
  }, [data]);

  const renderCredentials = useMemo(() => {
    if (!data) return null;

    const { jean_lutz_username, basic_password, advanced_password } = data;

    let result = [
      {
        label: 'Username',
        value: jean_lutz_username || '-',
      },
    ];

    if (!AcIsUndefined(basic_password) && is(ROLES.OPERATOR, data.roles))
      result.push({ label: 'Basic password', value: basic_password || '-' });
    if (
      !AcIsUndefined(advanced_password) &&
      is(ROLES.SERVICE_ENGINEER, data.roles)
    )
      result.push({
        label: 'Advanced password',
        value: advanced_password || '-',
      });

    return (
      <AcDetailsCard
        title={TITLES.DETAILS}
        items={result}
        edit={displayEditCredentialsModal}
      />
    );
  }, [data]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow>
          <AcColumn xs={12} sm={6}>
            <AcRow>
              <AcColumn>
                <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
                  Details
                </AcHeading>
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>{renderDetails}</AcColumn>
            </AcRow>
          </AcColumn>

          <AcColumn xs={12} sm={6}>
            <AcRow>
              <AcColumn>
                <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
                  Settings
                </AcHeading>
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>{renderSettings}</AcColumn>
            </AcRow>
          </AcColumn>
        </AcRow>

        {data && (is(ROLES.OPERATOR) || is(ROLES.SERVICE_ENGINEER)) && (
          <AcCheckPermissions
            allowed={[
              PERMISSIONS.BASIC_PASSWORD.READ,
              PERMISSIONS.ADVANCED_PASSWORD.READ,
            ]}
          >
            <AcRow>
              <AcColumn xs={12} sm={6}>
                <AcRow className={'h-margin-bottom-25'}>
                  <AcColumn>
                    <AcHeading tag={'h2'} rank={5}>
                      Control unit credentials
                    </AcHeading>
                    <AcRichContent
                      content={
                        '<p>These credentials give access to control units you are assigned to.</p>'
                      }
                    />
                  </AcColumn>
                </AcRow>

                <AcRow>
                  <AcColumn>{renderCredentials}</AcColumn>
                </AcRow>
              </AcColumn>
            </AcRow>
          </AcCheckPermissions>
        )}
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcProfileOverviewTab));
