import loadable from '@loadable/component';

// Imports => Assets
import MapMarkerProject from '@assets/images/markers/map-marker-project.png';

import MapMarkerHammer from '@assets/images/markers/map-marker-hammer.png';
import MapMarkerControlUnit from '@assets/images/markers/map-marker-control-unit.png';
import MapMarkerPowerpack from '@assets/images/markers/map-marker-powerpack.png';

import MapMarkerEquipmentHammer from '@assets/images/markers/map-marker-equipment-hammer.png';
import MapMarkerEquipmentControlUnit from '@assets/images/markers/map-marker-equipment-control-unit.png';
import MapMarkerEquipmentPowerpack from '@assets/images/markers/map-marker-equipment-powerpack.png';

import MapMarkerPile from '@assets/images/markers/map-marker-pile.png';

// Imports => Constants
import { KEYS } from '@constants';

export const AcGetMapMarkerImage = (type, entity) => {
	let result = null;
	if (!type) return result;

	const _type = type.replace('-', '_').replace(/ /g, '_').toLowerCase();

	switch (_type) {
		case KEYS.PROJECT:
		case KEYS.PROJECTS:
			result = MapMarkerProject;
			break;

		case KEYS.CONTROL_UNIT:
		case KEYS.CONTROL_UNITS:
			if (entity === KEYS.EQUIPMENT) result = MapMarkerEquipmentControlUnit;
			else result = MapMarkerControlUnit;
			break;

		case KEYS.HAMMER:
		case KEYS.HAMMERS:
			if (entity === KEYS.EQUIPMENT) result = MapMarkerEquipmentHammer;
			else result = MapMarkerHammer;
			break;

		case KEYS.POWERPACK:
		case KEYS.POWERPACKS:
		case KEYS.POWER_PACKS:
			if (entity === KEYS.EQUIPMENT) result = MapMarkerEquipmentPowerpack;
			else result = MapMarkerPowerpack;
			break;

		case KEYS.PILE:
		case KEYS.PILES:
			result = MapMarkerPile;
			break;

		default:
	}

	return result;
};

export default AcGetMapMarkerImage;
