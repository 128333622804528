import loadable from '@loadable/component';

// Imports => Views: Auth
const AcForgotPassword = loadable(() =>
  import('@views/ac-forgot-password/ac-forgot-password')
);
const AcLogin = loadable(() => import('@views/ac-login/ac-login'));
const AcResetPassword = loadable(() =>
  import('@views/ac-reset-password/ac-reset-password')
);

// Imports => Views: Changelog
const AcChangelog = loadable(() => import('@views/ac-changelog/ac-changelog'));

// Imports => Views: Settings
const AcSettings = loadable(() => import('@views/ac-settings/ac-settings'));
const AcSupport = loadable(() => import('@views/ac-support/ac-support'));

// Imports => Views: Dashboard
const AcDashboard = loadable(() => import('@views/ac-dashboard/ac-dashboard'));
const AcProjectDashboard = loadable(() =>
  import('@views/ac-project-dashboard/ac-project-dashboard')
);
const AcEquipmentDashboard = loadable(() =>
  import('@views/ac-equipment-dashboard/ac-equipment-dashboard')
);
const AcControlUnitDashboard = loadable(() =>
  import('@views/ac-control-unit-dashboard/ac-control-unit-dashboard')
);

// Imports => Views: Company
const AcCompanyDetail = loadable(() =>
  import('@views/ac-company-detail/ac-company-detail')
);
const AcCompanyOverview = loadable(() =>
  import('@views/ac-company-overview/ac-company-overview')
);

// Imports => Views: Configuration
const AcConfigurationDetail = loadable(() =>
  import('@views/ac-configuration-detail/ac-configuration-detail')
);
const AcConfigurationOverview = loadable(() =>
  import('@views/ac-configuration-overview/ac-configuration-overview')
);

// Imports => Views: Contract
const AcContractDetail = loadable(() =>
  import('@views/ac-contract-detail/ac-contract-detail')
);
const AcContractOverview = loadable(() =>
  import('@views/ac-contract-overview/ac-contract-overview')
);

// Imports => Views: Control Unit
const AcControlUnitDetail = loadable(() =>
  import('@views/ac-control-unit-detail/ac-control-unit-detail')
);
const AcControlUnitLiveview = loadable(() =>
  import('@views/ac-control-unit-liveview/ac-control-unit-liveview')
);
const AcControlUnitOverview = loadable(() =>
  import('@views/ac-control-unit-overview/ac-control-unit-overview')
);
const AcControlUnitTypeDetail = loadable(() =>
  import('@views/ac-control-unit-type-detail/ac-control-unit-type-detail')
);
const AcControlUnitTypeOverview = loadable(() =>
  import('@views/ac-control-unit-type-overview/ac-control-unit-type-overview')
);

// Imports => Views: Hammer
const AcHammerDetail = loadable(() =>
  import('@views/ac-hammer-detail/ac-hammer-detail')
);
const AcHammerOverview = loadable(() =>
  import('@views/ac-hammer-overview/ac-hammer-overview')
);
const AcHammerTypeDetail = loadable(() =>
  import('@views/ac-hammer-type-detail/ac-hammer-type-detail')
);
const AcHammerTypeOverview = loadable(() =>
  import('@views/ac-hammer-type-overview/ac-hammer-type-overview')
);

// Imports => Views: Powerpack
const AcPowerpackDetail = loadable(() =>
  import('@views/ac-powerpack-detail/ac-powerpack-detail')
);
const AcPowerpackOverview = loadable(() =>
  import('@views/ac-powerpack-overview/ac-powerpack-overview')
);
const AcPowerpackTypeDetail = loadable(() =>
  import('@views/ac-powerpack-type-detail/ac-powerpack-type-detail')
);
const AcPowerpackTypeOverview = loadable(() =>
  import('@views/ac-powerpack-type-overview/ac-powerpack-type-overview')
);

// Imports => Views: Profile
const AcProfile = loadable(() => import('@views/ac-profile/ac-profile'));

// Imports => Views: Project
const AcProjectDetail = loadable(() =>
  import('@views/ac-project-detail/ac-project-detail')
);
const AcProjectOverview = loadable(() =>
  import('@views/ac-project-overview/ac-project-overview')
);

// Imports => Views: User
const AcUserDetail = loadable(() =>
  import('@views/ac-user-detail/ac-user-detail')
);
const AcUserOverview = loadable(() =>
  import('@views/ac-user-overview/ac-user-overview')
);

// Imports => Views: Welcome
const AcWelcome = loadable(() => import('@views/ac-welcome/ac-welcome'));

// Imports => Views: MyCompany
const AcMyCompany = loadable(() =>
  import('@views/ac-my-company/ac-my-company')
);

export {
  AcLogin,
  AcForgotPassword,
  AcResetPassword,
  AcProfile,
  AcChangelog,
  AcDashboard,
  AcCompanyDetail,
  AcCompanyOverview,
  AcConfigurationDetail,
  AcConfigurationOverview,
  AcContractDetail,
  AcContractOverview,
  AcControlUnitDashboard,
  AcControlUnitDetail,
  AcControlUnitLiveview,
  AcControlUnitOverview,
  AcControlUnitTypeDetail,
  AcControlUnitTypeOverview,
  AcEquipmentDashboard,
  AcHammerDetail,
  AcHammerOverview,
  AcHammerTypeDetail,
  AcHammerTypeOverview,
  AcMyCompany,
  AcPowerpackDetail,
  AcPowerpackOverview,
  AcPowerpackTypeDetail,
  AcPowerpackTypeOverview,
  AcProjectDashboard,
  AcProjectDetail,
  AcProjectOverview,
  AcSettings,
  AcSupport,
  AcUserDetail,
  AcUserOverview,
  AcWelcome,
};
