export const AcSanitize = (input) => {
  if (typeof input === 'undefined' || input === null) return input;

  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '/': '&#x2F;',
  };

  const reg = /[&<>"'/]/gi;
  const result = input.replace(reg, (match) => map[match]);

  return result.trim();
};

export default AcSanitize;
