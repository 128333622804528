export const AcGenerateMapMarkerElement = (
	src,
	width = 32,
	height = 42,
	x = -16,
	y = -40.5,
	label = null
) => {
	let iconElement = document.createElement('img');
	iconElement.src = src; // Set the image URL from marker.getAttribute('rel')
	iconElement.width = width; // Scaled size width
	iconElement.height = height; // Scaled size height

	// Force enable pointerEvents so JS events are actually properly triggered
	iconElement.style.pointerEvents = 'all';

	// Adjust origin and anchor using CSS or JS
	iconElement.style.position = 'absolute';
	iconElement.style.transform = `translate(${x}px, ${y}px)`; // Adjusting for anchor point

	if (label) {
		const labelElement = document.createElement('span');
		labelElement.classList = 'pilelist-label';
		labelElement.style.color = '#4a4a4a';
		labelElement.style.fontSize = '10px';
		labelElement.append(label);

		const divElement = document.createElement('div');

		divElement.append(iconElement);
		divElement.append(labelElement);

		iconElement = divElement;
	}

	return iconElement;
};

export default AcGenerateMapMarkerElement;
