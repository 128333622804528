// Imports => DayJS
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// Imports => Config
import { getLocale, setLocale } from '@config/locale';

export const AcFormatDate = (input, from = 'DD-MM-YYYY', to = 'DD MMM Y') => {
  dayjs.extend(localizedFormat);
  dayjs.extend(relativeTime);
  dayjs.extend(advancedFormat);

  // Set and Get locale settings
  setLocale();
  const locale = getLocale();

  // Verify valid date / dayjs object
  if (!dayjs(input).isValid()) return input;

  const format = null;
  const _from = from ? from : format;

  // Format input
  const output = _from
    ? dayjs(input, _from).format(to)
    : dayjs(input).format(to);

  return output;
};

export const AcGetTimeDifference = (
  time,
  now = dayjs(),
  format = null,
  ms = false
) => {
  setLocale();

  const start = dayjs(now).isValid() ? dayjs(now) : dayjs();
  const end = format ? dayjs(time, format) : dayjs(time);
  const diff = dayjs.duration(start.diff(end));

  if (ms) return diff.asMilliseconds();
  return diff;
};

export const AcGetDaysRemaining = (date, now = dayjs()) => {
  setLocale();

  const start = dayjs(now).isValid() ? dayjs(now) : dayjs();
  const end = dayjs(date);
  const diff = end.diff(start, 'days');

  return diff;
};

export const sortDatesDesc = (firstDate, secondDate) => {
  return dayjs(firstDate, 'YYYY-MM-DD HH:mm').isAfter(
    dayjs(secondDate, 'YYYY-MM-DD HH:mm')
  )
    ? -1
    : 1;
};

export default AcFormatDate;
