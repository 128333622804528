import FileSaver from 'file-saver';

export const AcDownloadFile = (data, filename, mime, blob = true) => {
	return new Promise((resolve) => {
		let blob = null;

		if (mime) {
			blob = new Blob([data], { type: mime || 'application/octet-stream' });
		} else {
			blob = new Blob([data]);
		}

		if (blob) FileSaver.saveAs(blob, filename);
		else FileSaver.saveAs(data, filename);

		resolve();
	});
};

export default AcDownloadFile;
